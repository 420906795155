import { useCallback, useContext, useEffect, useRef } from 'react';

import { AnalyticsContext } from './context';
import type { AnalyticsActionType } from './enums';
import { useExtraProperties } from './extraProperties';
import type { AnalyticsEventData } from './types';
import { useLogAnalyticsEvent } from './useLogAnalyticsEvent';

export function useEventCallback<Args extends unknown[], R>(
  callback: (...args: Args) => R,
): (...args: Args) => R {
  const callbackRef = useRef<(...args: Args) => R>(callback);
  callbackRef.current = callback;

  return useCallback((...args) => callbackRef.current(...args), []);
}

export const useAnalyticsContext = () => useContext(AnalyticsContext);

export const useAnalyticsInitialization = () => {
  const { analytics, postponeInitialization } = useContext(AnalyticsContext);

  return {
    analytics,
    postponeInitialization,
  };
};

type AnalyticsPropertyType = Omit<AnalyticsEventData, 'action'> &
  Record<string, boolean | number | string>;

type MountChangeEvent =
  | AnalyticsActionType
  | { name: AnalyticsActionType; params?: AnalyticsPropertyType };

interface UseAnalyticsParams {
  mountEvent?: MountChangeEvent;
  unmountEvent?: MountChangeEvent;
}

// Create an analytics hook that we can use with other components.
export const useAnalytics = ({
  mountEvent,
  unmountEvent,
}: UseAnalyticsParams = {}) => {
  const {
    analytics,
    enabled,
    currentPageIdRef,
    setCurrentPageId,
    pageMapper,
    section,
    enqueueAction,
  } = useContext(AnalyticsContext);
  const logAnalyticsEvent = useLogAnalyticsEvent();
  const { isLoading, ...extraProperties } = useExtraProperties();

  const bufferedEventsRef = useRef<(() => void)[]>([]);
  const extraPropertiesRef = useRef(extraProperties);
  extraPropertiesRef.current = extraProperties;

  const trackUiInteraction = useEventCallback(
    (action: AnalyticsActionType, properties?: AnalyticsPropertyType) => {
      const fn = () => {
        const data = {
          ...extraPropertiesRef.current,
          ...properties,
          page_id: currentPageIdRef.current,
          action,
        };

        if (enabled && analytics) {
          enqueueAction(() => {
            void analytics.track('ui_interaction', data);
          });
        }

        logAnalyticsEvent(['ui_interaction', data.action], data);
      };

      if (isLoading) {
        bufferedEventsRef.current.push(fn);
      } else {
        fn();
      }
    },
  );

  function trackMountChangeEvent(mountChangeEvent: MountChangeEvent): void {
    if (typeof mountChangeEvent === 'object') {
      trackUiInteraction(mountChangeEvent.name, mountChangeEvent.params);
    } else {
      trackUiInteraction(mountChangeEvent);
    }
  }

  const trackPage = useEventCallback((path: string, params: object) => {
    const pageId = pageMapper[path] || path;
    const name = `${section}_${pageId}`;

    setCurrentPageId(pageId);

    const fn = () => {
      const properties = {
        ...extraPropertiesRef.current,
        match_path: path,
        page_id: pageId,
        ...Object.fromEntries(
          Object.entries(params).map(([key, value]) => [
            key.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`),
            value,
          ]),
        ),
      };

      if (enabled && analytics) {
        enqueueAction(() => {
          void analytics.page(section, name, properties);
        });
      }

      logAnalyticsEvent(['page_view', section, name], properties);
    };

    if (isLoading) {
      bufferedEventsRef.current.push(fn);
    } else {
      fn();
    }
  });

  if (!isLoading && bufferedEventsRef.current.length > 0) {
    bufferedEventsRef.current.forEach((fn) => fn());
    bufferedEventsRef.current = [];
  }

  const mountedRef = useRef(false);

  useEffect(() => {
    // Checking for mounted condition because in Strict Mode while development
    // React triggers each effect twice.
    if (mountedRef.current) {
      return;
    }
    mountedRef.current = true;

    if (mountEvent) {
      trackMountChangeEvent(mountEvent);
    }

    if (unmountEvent) {
      return () => trackMountChangeEvent(unmountEvent);
    }

    return undefined;

    // Hook should be called exactly once.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    trackUiInteraction,
    trackPage,
  };
};

export type TrackCallback = ReturnType<
  typeof useAnalytics
>['trackUiInteraction'];
